import { CSSProperties, FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import './lazyLoadImage.scss';

interface Props{
    lstSrc: string[];
    alt: string;
    style?: CSSProperties | undefined;
    className?: string;
}

export const LazyLoadImage: FunctionComponent<Props> = props => {
    const imgRef = useRef<HTMLImageElement>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [currentSrc, setCurrentSrc] = useState<string>('')

    const loadImages = useCallback((lstSrc: string[], index = 0) => {
        if(lstSrc.length <= index)
            return;

        const image = new Image();

        image.onload = () => {
            setIsLoading(index === 0);

            setCurrentSrc(lstSrc[index])
            loadImages(lstSrc, ++index);
        }

        image.onerror = () => loadImages(lstSrc, ++index);

        image.src = lstSrc[index];
        console.log('will load', lstSrc[index])
    }, [imgRef, setIsLoading])

    useEffect(() => {
        loadImages(props.lstSrc)
    }, [props.lstSrc])

    const loadingStyle = isLoading ? 'loading' : '';
    const className = `lazyLoadImage ${props.className} ${loadingStyle}`;
    return (
        <>
            <img ref={imgRef} src={currentSrc} alt={props.alt} style={props.style} className={className} />
        </>
    )
}
