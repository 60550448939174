import { FunctionComponent } from 'react';
import { Cover } from '../Cover'
import { AboutUs } from '../AboutUs'
import { Footer } from '../Footer'
import { Outro } from '../Outro'
import { Address } from '../Address'
import { HouseMenu } from "../HouseMenu";

export const Home: FunctionComponent = () => {
    return <div>
        <Cover/>
        <AboutUs/>
        <Address/>
        <HouseMenu/>
        <Outro/>
        <Footer/>
    </div>
}
