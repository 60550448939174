import { FunctionComponent } from 'react';
import InstagramImage from '../../assets/images/icons/instagram.png';
import './footer.scss';

export const Footer: FunctionComponent = () => {
    return (
        <div className="footer">
            <div className="text">
                ©2024 | Restaurante Gordo. Todos os direitos reservados
            </div>
            <div className="actions">
                <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/gordo_smokehouse">
                    <img src={InstagramImage} alt="Instagram" />
                </a>
            </div>
        </div>
    )
}
