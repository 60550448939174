import { FunctionComponent, useEffect, useState } from 'react'
import CoverImage1Medium from '../../assets/images/capa-1-medium.jpg'
import CoverImage1Small from '../../assets/images/capa-1-small.jpg';
import CoverImage1Full from '../../assets/images/capa-1-full.jpg';
import CoverImage2Medium from '../../assets/images/capa-2-medium.jpg'
import CoverImage2Small from '../../assets/images/capa-2-small.jpg';
import CoverImage2Full from '../../assets/images/capa-2-full.jpg';
import CoverImage3Medium from '../../assets/images/capa-3-medium.jpg'
import CoverImage3Small from '../../assets/images/capa-3-small.jpg';
import CoverImage3Full from '../../assets/images/capa-3-full.jpg';
import CoverImage4Medium from '../../assets/images/capa-4-medium.jpg'
import CoverImage4Small from '../../assets/images/capa-4-small.jpg';
import CoverImage4Full from '../../assets/images/capa-4-full.jpg';
import LogoImage from '../../assets/images/logo.png'
import InstagramImage from '../../assets/images/icons/instagram.png'
import './cover.scss'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { LazyLoadImage } from '../utils/LazyLoadImage'

const imgs = [
    {
        img: [CoverImage1Small, CoverImage1Medium, CoverImage1Full],
        position: '50% 60%',
        alt: 'New york strip with huge knife',
    },
    {
        img: [CoverImage2Small, CoverImage2Medium, CoverImage2Full],
        position: '70% 70%',
        alt: 'Bife ancho well cutted',
    },
    {
        img: [CoverImage3Small, CoverImage3Medium, CoverImage3Full],
        position: '50% 30%',
        alt: 'Sausage with some chimichurry',
    },
    {
        img: [CoverImage4Small, CoverImage4Medium, CoverImage4Full],
        position: '50% 30%',
        alt: 'Some pork belly',
    },
]

export const Cover: FunctionComponent = () => {
    const navigate = useNavigate()

    const [indexCurrent, setIndexCurrent] = useState(0)

    const { length } = imgs

    const currentIndex = indexCurrent % length
    const lastIndex = currentIndex === 0 ? length - 1 : currentIndex - 1
    const nextIndex = currentIndex === length - 1 ? 0 : currentIndex + 1

    useEffect(() => {
        const tms = setInterval(() => {
            setIndexCurrent(indexCurrent + 1)
        }, 8000)

        return () => clearInterval(tms)
    }, [indexCurrent])

    const renderImage = (index: number, style: string) => {
        const image = imgs[index]

        return (
            <LazyLoadImage
                key={index}
                lstSrc={image.img}
                alt={image.alt}
                style={{ objectPosition: image.position }}
                className={`image-cover ${style}`}
            />
        )
    }

    return (
        <div className="cover">
            {renderImage(lastIndex, 'last-image')}
            {renderImage(currentIndex, 'current-image show')}
            {renderImage(nextIndex, 'next-image')}

            <div className="logo">
                <img src={LogoImage} alt="Gordo smokehouse logo" />
            </div>

            <div className="social-network">
                <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/gordo_smokehouse">
                    <img src={InstagramImage} alt="Instagram" />
                </a>
            </div>
        </div>
    )
}


