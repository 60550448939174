import { FunctionComponent } from 'react'
import LogoImage from '../../assets/images/logo.png';
import './outro.scss'

export const Outro: FunctionComponent = () => {
    return (
        <div className="outro">
            <img src={LogoImage}/>
        </div>
    )
}
