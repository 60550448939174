import { FunctionComponent } from "react";
import './houseMenu.scss'

import Image14Small from '../../assets/images/location/14-small.jpg';
import Image14Medium from '../../assets/images/location/14-medium.jpg';
import Image14Full from '../../assets/images/location/14-full.jpg';

import Image15Small from '../../assets/images/location/15-small.jpg';
import Image15Medium from '../../assets/images/location/15-medium.jpg';
import Image15Full from '../../assets/images/location/15-full.jpg';

import Image16Small from '../../assets/images/location/16-small.jpg';
import Image16Medium from '../../assets/images/location/16-medium.jpg';
import Image16Full from '../../assets/images/location/16-full.jpg';

import Image17Small from '../../assets/images/location/17-small.jpg';
import Image17Medium from '../../assets/images/location/17-medium.jpg';
import Image17Full from '../../assets/images/location/17-full.jpg';
import { LazyLoadImage } from "../utils/LazyLoadImage";
import MaskCollage from "../../assets/images/mask.png";

const allImages = [
    [Image14Small, Image14Medium, Image14Full],
    [Image15Small, Image15Medium, Image15Full],
    [Image16Small, Image16Medium, Image16Full],
    [Image17Small, Image17Medium, Image17Full]
]

const typicals = [
    "Entradas",
    "Carnes",
    "Acompanhamentos",
    "Sobremesas"
]
export const HouseMenu: FunctionComponent = () => {

    return (
        <div className="houseMenu">
            <div className="mask mask-left">
                <img src={MaskCollage} alt="mask"/>
            </div>
            <div className="content">
                <div className="title">Menu</div>

                <div className="images">
                    {allImages.map((img, i) => (
                        <div className="wrap-img" key={i}>
                            <a rel="noopener noreferrer"
                               target="_blank"
                               href={"https://gordo-smokehouse.s3.sa-east-1.amazonaws.com/CARDAPIO-NOVO-v2.pdf"}>
                                <LazyLoadImage lstSrc={img} alt={`Local ${i + 1}`}/>
                                <div className="typical">{typicals[i]}</div>
                            </a>
                        </div>

                    ))}
                </div>
                <div className="action">
                    <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href={"https://gordo-smokehouse.s3.sa-east-1.amazonaws.com/CARDAPIO-NOVO-v2.pdf"}>
                        <div className="outline-btn">Ver cardápio</div>
                    </a>
                </div>

            </div>
            <div className="mask mask-right">
                <img src={MaskCollage} alt="mask"/>
            </div>

        </div>
    )
}