import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { Index } from './components/utils/Navbar'
import { Home } from './components/Home'

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Index />
      <div className="container">
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </BrowserRouter>
  )
}

export default App
