import { createSlice } from '@reduxjs/toolkit'

interface CartState{
    products: string[]
}

const initialState: CartState = {
    products: []
}

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {}
})

export default cartSlice.reducer;
