import React from 'react'
import './navbar.scss'

const url = 'https://store.gordosmokehouse.com/'

export const Index: React.FC = () => {
    return (
        <nav>
            <div className="action">
                <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={url}>

                    <div className="outline-btn">
                        Comprar
                    </div>
                </a>
            </div>
        </nav>
    )
}
