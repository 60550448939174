import './aboutUs.scss';
import { FunctionComponent } from 'react';
import ArrowSvg from '../../assets/images/icons/arrow-down.svg';
import MaskCollage from '../../assets/images/mask.png';

export const AboutUs: FunctionComponent = () => {
    return (
        <div className='about-us'>
            <div className="mask mask-left">
                <img src={MaskCollage} alt="mask" />
            </div>
            <div className="content">
                <img src={ArrowSvg} alt="arrow-down" />
                <div className="title">
                    Na criteriosa seleção da carne e um time de especialistas
                </div>
                <p>
                    Em <b>2023</b>, nasceu o <b>Gordo Smokehouse</b>, um local de referência para os amantes de churrasco e gastronomia de qualidade. Com uma seleção criteriosa de carnes e cortes nobres, não somos apenas um restaurante, mas uma experiência culinária.
                </p>
                <p>
                     Cada peça é submetida a uma <b>carga de fumaça</b> específica, um processo meticuloso que realça os sabores e aromas, conferindo-lhes uma <b>textura e suculência</b> incomparáveis.
                </p>
                <p>
                    A jornada do <b>Gordo Smokehouse</b> não é apenas sobre comida, mas também sobre paixão e dedicação à tradição do <b>churrasco</b>. Desde os primeiros dias, a equipe se comprometeu a oferecer não apenas uma refeição, mas <b>aguçar o paladar</b>, onde cada mordida conta uma história de sabor e excelência.
                </p>
            </div>
            <div className="mask mask-right">
                <img src={MaskCollage} alt="mask" />
            </div>
        </div>
    )
}
