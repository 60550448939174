import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import LocationIcon from '../../assets/images/icons/location.svg';
import PhoneIcon from '../../assets/images/icons/phone.svg';
import ScheduleIcon from '../../assets/images/icons/schedule.svg';
import Image1Small from '../../assets/images/location/1-small.jpg';
import Image1Medium from '../../assets/images/location/1-medium.jpg';
import Image1Full from '../../assets/images/location/1-full.jpg';
import Image2Small from '../../assets/images/location/2-small.jpg';
import Image2Medium from '../../assets/images/location/2-medium.jpg';
import Image2Full from '../../assets/images/location/2-full.jpg';
import Image3Small from '../../assets/images/location/3-small.jpg';
import Image3Medium from '../../assets/images/location/3-medium.jpg';
import Image3Full from '../../assets/images/location/3-full.jpg';
import Image4Small from '../../assets/images/location/4-small.jpg';
import Image4Medium from '../../assets/images/location/4-medium.jpg';
import Image4Full from '../../assets/images/location/4-full.jpg';
import Image5Small from '../../assets/images/location/5-small.jpg';
import Image5Medium from '../../assets/images/location/5-medium.jpg';
import Image5Full from '../../assets/images/location/5-full.jpg';
import Image6Small from '../../assets/images/location/6-small.jpg';
import Image6Medium from '../../assets/images/location/6-medium.jpg';
import Image6Full from '../../assets/images/location/6-full.jpg';
import Image7Small from '../../assets/images/location/7-small.jpg';
import Image7Medium from '../../assets/images/location/7-medium.jpg';
import Image7Full from '../../assets/images/location/7-full.jpg';
import Image8Small from '../../assets/images/location/8-small.jpg';
import Image8Medium from '../../assets/images/location/8-medium.jpg';
import Image8Full from '../../assets/images/location/8-full.jpg';
import Image9Small from '../../assets/images/location/9-small.jpg';
import Image9Medium from '../../assets/images/location/9-medium.jpg';
import Image9Full from '../../assets/images/location/9-full.jpg';
import Image10Small from '../../assets/images/location/10-small.jpg';
import Image10Medium from '../../assets/images/location/10-medium.jpg';
import Image10Full from '../../assets/images/location/10-full.jpg';
import Image11Small from '../../assets/images/location/11-small.jpg';
import Image11Medium from '../../assets/images/location/11-medium.jpg';
import Image11Full from '../../assets/images/location/11-full.jpg';
import Image12Small from '../../assets/images/location/12-small.jpg';
import Image12Medium from '../../assets/images/location/12-medium.jpg';
import Image12Full from '../../assets/images/location/12-full.jpg';
import Image13Small from '../../assets/images/location/13-small.jpg';
import Image13Medium from '../../assets/images/location/13-medium.jpg';
import Image13Full from '../../assets/images/location/13-full.jpg';
import CircleArrowRight from '../../assets/images/icons/arrow-circle-right.svg';
import CircleArrowLeft from '../../assets/images/icons/arrow-circle-left.svg';
import './address.scss'
import { LazyLoadImage } from '../utils/LazyLoadImage'

const allImages = [
    [Image1Small, Image1Medium, Image1Full],
    [Image2Small, Image2Medium, Image2Full],
    [Image3Small, Image3Medium, Image3Full],
    [Image4Small, Image4Medium, Image4Full],
    [Image5Small, Image5Medium, Image5Full],
    [Image6Small, Image6Medium, Image6Full],
    [Image7Small, Image7Medium, Image7Full],
    [Image8Small, Image8Medium, Image8Full],
    [Image9Small, Image9Medium, Image9Full],
    [Image10Small, Image10Medium, Image10Full],
    [Image11Small, Image11Medium, Image11Full],
    [Image12Small, Image12Medium, Image12Full],
    [Image13Small, Image13Medium, Image13Full],
]


const isOpened = (referenceDate: Date = new Date()): boolean => {

    const dateInBsb = new Date(referenceDate.toLocaleString("en-US", {timeZone: "America/Sao_Paulo"}));

    if (dateInBsb.getDay() === 1)
        return false

    const actualHour = dateInBsb.getHours();

    return actualHour >= 18 && actualHour < 23;
}


export const Address: FunctionComponent = () => {
    const [selectedImgIndex, setSelectedImgIndex] = useState<number>();

    const isOpenRightNow = isOpened();

    const openImage = (indexImage: number) => {
        setSelectedImgIndex(indexImage);
    }

    const back = (indexImage: number) => {
        if (indexImage === 0)
            return

        const index = indexImage - 1;

        setSelectedImgIndex(index);
    }

    const next = (indexImage: number) => {
        const index = indexImage + 1;
        if (index >= allImages.length)
            return

        setSelectedImgIndex(index);
    }

    const popupRef = useRef<HTMLDivElement>(null);
    const handleClickOutside = useCallback((event: MouseEvent) => {
        if(!popupRef.current || popupRef.current?.contains(event.target as Node))
            return;

        setSelectedImgIndex(undefined);
    }, [popupRef])
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    return (
        <div className="address">
            <h1>Endereço</h1>

            <div className="content">
                <div className="info">
                    <div className="location">
                        <img src={LocationIcon} alt="location"/>
                        <a target="_blank" href="https://maps.app.goo.gl/8c8ZYPU7gARmTip88?g_st=ic">Av. das Araucárias,
                            1325 - Águas Claras, Brasília - DF</a>
                    </div>

                    <div className="phone">
                        <img src={PhoneIcon} alt="phone-number"/>
                        <a href="tel:+5561995510808" target="_blank">(61) 9 9551 0808</a>
                    </div>

                    <div className={`schedule ${isOpenRightNow ? "isOpen" : ""}`}>
                        <img src={ScheduleIcon} alt="schedule"/>
                        <span>Aberto Terça a Domingo 18h as 23h</span>
                    </div>

                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3838.3225855944047!2d-48.02184118745145!3d-15.839633984743728!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x935bcdda482b52fd%3A0x739e5309b301560d!2sGordo%20Smokehouse!5e0!3m2!1sen!2sbr!4v1709782682024!5m2!1sen!2sbr"
                        className="map"
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>

                <div className="portfolio">
                    {allImages.map((img, i) => (
                        <div className="wrap-img" key={i} onClick={() => openImage(i)}>
                            <LazyLoadImage lstSrc={img} alt={`Local ${i + 1}`}/>
                        </div>
                    ))}
                </div>
            </div>
            {(selectedImgIndex !== undefined && <div className="popup">
                    <div className="container" ref={popupRef}>
                        {(selectedImgIndex != 0 && <div className="back" onClick={() => back(selectedImgIndex)}>
                            <img src={CircleArrowLeft} alt="circle-arrow-left"/>
                        </div>)}
                        <div className="photo">
                            <LazyLoadImage lstSrc={allImages[selectedImgIndex]} alt={`Local ${allImages[selectedImgIndex]}`}/>
                        </div>
                        {(selectedImgIndex !== (allImages.length - 1) &&
                            <div className="next" onClick={() => next(selectedImgIndex)}>
                                <img src={CircleArrowRight} alt="circle-arrow-rigth"/>
                            </div>)}
                    </div>

                </div>
            )}
        </div>
    )
}
