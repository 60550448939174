import { AnyAction, configureStore, ThunkAction } from '@reduxjs/toolkit'
import cartSlice from './cartSlice'

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    cart: cartSlice,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>
export type AppDispatch = typeof store.dispatch
